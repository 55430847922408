import React from "react";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
function Navigation() {
  const navigate = useNavigate();
  return (
    <Navbar>
      <nav >
        <Nav className="nav-padding">
          <Nav.Link onClick={() => navigate("/")} className="nav">
            Home{" "}
          </Nav.Link>
          <Nav.Link className="nav" onClick={() => navigate("/about")}>
            About{" "}
          </Nav.Link>
          <Nav.Link className="nav" onClick={() => navigate("/Blog")}>
            Blog
          </Nav.Link>
          <Nav.Link className="nav" onClick={() => navigate("/Projects")}>
            Projects
          </Nav.Link>
          <Nav.Link className="nav" onClick={() => navigate("/Skills")}>
            Skills
          </Nav.Link>
          <Nav.Link className="nav" onClick={() => navigate("/Contact")}>
            Contact
          </Nav.Link>
        </Nav>
      </nav>
    </Navbar>
  );
}
export default Navigation;
