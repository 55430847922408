import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Badge } from "react-bootstrap";
import ReactToGithub from "./blog.json";
import { dracula, CodeBlock } from "react-code-blocks";

//Adding the blogpost blocks from blog.json
let blogpost1 = ReactToGithub.ReactOnGithub.blogpost1;
let blogpost2 = ReactToGithub.ReactOnGithub.blogpost2;
let blogpost3 = ReactToGithub.ReactOnGithub.blogpost3;
let blogpost4 = ReactToGithub.ReactOnGithub.blogpost4;
let blogpost5 = ReactToGithub.ReactOnGithub.blogpost5;
let blogpost6 = ReactToGithub.ReactOnGithub.blogpost6;
let codeblock1 = ReactToGithub.ReactOnGithub.codeblock1;
let codeblock2 = ReactToGithub.ReactOnGithub.codeblock2;
let codeblock3 = ReactToGithub.ReactOnGithub.codeblock3;
let conclusion = ReactToGithub.ReactOnGithub.conclusion;

function ReactOnGithub() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid="md" className="border colspacing">
        <div className="colSpacing pg ">
          <div className="pgheader alignCenter ">
            <h1>Deploy React App On Github</h1>
          </div>
        </div>
        <Row className="colSpacing">
          <Col xs={8} className="pg3">
            <p>{blogpost1}</p>
            <br></br>
            <p>{blogpost2}</p>
            <br></br>
            <p>{blogpost3}</p>
            <br></br>
            <CodeBlock
              text={codeblock1}
              language="jsx"
              showLineNumbers={false}
              theme={dracula}
              className="cardImg"
            />
            <br></br>
            <p>{blogpost4}</p>
            <br></br>

            <p>{blogpost5}</p>
            <CodeBlock
              text={codeblock2}
              language="jsx"
              showLineNumbers={false}
              theme={dracula}
              className="cardImg"
            />
            <br></br>
            <p>{blogpost6}</p>
            <br></br>
            <CodeBlock
              text={codeblock3}
              language="jsx"
              showLineNumbers={false}
              theme={dracula}
              className="cardImg"
            />
            <br></br>
            <p>{conclusion}</p>
            <br></br>
          </Col>
          <Col xs={4} className="colSpacing">
            <h3 className="underline ">Tags:</h3>
            <Badge className="tags">Github</Badge>
            <Badge className="tags">React</Badge>
            <Badge className="tags">NPM</Badge>
            <Badge className="tags">Github Pages</Badge>
            <Badge className="tags">React-App</Badge>
            <Badge className="tags">Deploy React</Badge>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
export default ReactOnGithub;
