import React from "react";
import { Row, Col, Card, Container, ListGroup, Tab } from "react-bootstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import "react-vertical-timeline-component/style.min.css";
import WorkIcona from "../../images/db.png";
import WorkIconb from "../../images/hsa.png";
import WorkIconc from "../../images/psu.png";
import SchoolIcona from "../../images/uoft.png";
import SchoolIconb from "../../images/psu.png";
import SchoolIconc from "../../images/osu.png";
import code from "../../images/codeicon.png";
import {
  SiReact,
  SiHtml5,
  SiCss3,
  SiNodedotjs,
  SiMongodb,
  SiExpress,
  SiJavascript,
  SiBootstrap,
  SiMaterialui,
  SiVisualstudiocode,
  SiNextdotjs,
  SiMicrosoftoffice,
  SiMysql,
  SiNpm,
} from "react-icons/si";

function Skills() {
  return (
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      animate={{ opacity: 1, x: "0" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="colSpacing border">
        <div className="pgheader alignCenter colSpacing">
          <h1>Skills Page</h1>
        </div>
        <Row className="colSpacing pg">
          <Col xs={8} className="alignCenter colSpacing">
            <p>
              Welcome to my personal skills and experience page! I am a{" "}
              <span className="text">Operational Engineer</span> with{" "}
              <span className="text">5</span> years of experience in the
              industry. Throughout my career, I have developed a wide range of
              skills and expertise that have allowed me to excel in my field. On
              this page, you'll find a list of my skills and experience, as well
              as some of the highlights of my career so far. This is my
              portfolio of work for you to explore with the hope that you are
              able to get a glimpse of how I think as said in a tweet I like by
              Mitch Goldstein. I hope this page gives you a good understanding
              of my capabilities and what I can offer. Thank you for visiting!
            </p>
          </Col>
          <Col xs={4} className="colSpacing ">
            <h4 className=" underline alignCenter ">Different Tools I Use</h4>
            <SiReact size={50} className="icons" style={{ color: "#2596be" }} />
            <SiHtml5 size={50} className="icons" style={{ color: "#e34c26" }} />
            <SiCss3 size={50} className="icons" style={{ color: "#264de4" }} />
            <SiNodedotjs
              size={50}
              className="icons"
              style={{ color: "#68a063" }}
            />
            <SiBootstrap
              size={50}
              className="icons"
              style={{ color: "#663399" }}
            />
            <SiExpress
              size={50}
              className="icons"
              style={{ color: "#f0db4f" }}
            />
            <SiMongodb
              size={50}
              className="icons"
              style={{ color: "#049024" }}
            />
            <SiJavascript
              size={50}
              className="icons"
              style={{ color: "#f0db4f" }}
            />
            <SiMaterialui
              size={50}
              className="icons"
              style={{ color: "#0769ad" }}
            />
            <SiNpm size={50} className="icons" style={{ color: "#cb3837" }} />
            <SiVisualstudiocode
              size={50}
              className="icons"
              style={{ color: "#0078d7" }}
            />
            <SiNextdotjs
              size={50}
              className="icons"
              style={{ color: "#ffff" }}
            />
            <SiMysql size={50} className="icons" style={{ color: "#00598e" }} />
            <SiMicrosoftoffice
              size={50}
              className="icons"
              style={{ color: "#DC3E15" }}
            />
          </Col>
        </Row>

        <h2 className="pg colSpacing alignLeft underline">Skillset:</h2>
        <p className="pg">
          Given my wide range of skills that I have acquired over the years it
          would be difficult to list examples for them all. Please find bellow a
          list of skills I find most relevant in todays day and age based on my
          different fields and a simple example from my experience showing my
          ability to effectively utilize and turning such skills into my own.
          Also please do not hesitate to go through my resume and projects to be
          able to get a comprehensive understanding of my capacity to
          continuously learn and improve.
        </p>
        <Row className="pg">
          <Col className="alignCenter">
            <h3 className="pg underline">
              Industrial / Operational Engineering Experience
            </h3>
          </Col>
          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
            <Row className="skills">
              <Col sm={3}>
                <ListGroup>
                  <ListGroup.Item action href="#link1" className="skillsTab">
                    Continuous Improvement
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link2" className="skillsTab">
                    Project Management
                  </ListGroup.Item>
                  <ListGroup.Item action href="#link3" className="skillsTab">
                    Logistics & Inventory Management
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="#link1">
                    Continuous improvement is the ongoing process of identifying
                    and implementing ways to increase efficiency, effectiveness,
                    and overall quality in an organization. It involves
                    constantly seeking out new ways to improve processes,
                    products, and services, and making necessary changes to
                    achieve these improvements. My biggest accomplishment
                    regarding Continuous improvement was when I was working for
                    Hayel Saeed Anam. Managed to reduce machine failure by more
                    than 25% and saving 12% in operational overhead cost
                    annually. This allowed for a shift in maintenance strategy
                    from a reactive to a more proactive strategy in all their
                    manufacturing facilities.
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link2">
                    During my masters at Portland State University I embarked
                    with a group of my colleagues to develop a tool that would
                    help with the development of highly advanced engineering
                    projects. There are many different methodologies such as an
                    agile approach which is mostly used within the tech industry
                    but this focused on a more large scale development system. I
                    led the team towards developing a framework that worked
                    within the Initiating, Planning and first phase of the
                    Execution phases of the project. The framework was built to
                    help increase the rate of success while increasing the
                    budget prediction accuracy by 26% as well as the project
                    date completion accuracy by 15%.
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link3">
                    Spearheaded raw material acquisition from international
                    vendors at required price and quality points. I have also
                    worked on improvement of the inventory management systems in
                    different cases following a FILO systemic approach.This
                    allowed for a decrease in waste as well as move focus
                    towards the development of a regression model that would
                    link the manufacturing with the business operations to
                    forecast future needs.
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <Col className="alignCenter">
            <h3 className="pg underline">Web Development</h3>
          </Col>
          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
            <Row className="skills">
              <Col sm={3}>
                <ListGroup className="skillsTab">
                  <ListGroup.Item action href="#link1" className="skillsTab">
                    Operations Library
                  </ListGroup.Item>

                  <ListGroup.Item action href="#link2" className="skillsTab">
                    Read-Me Generator
                  </ListGroup.Item>

                  <ListGroup.Item action href="#link3" className="skillsTab">
                    Weather Dashboard
                  </ListGroup.Item>

                  <ListGroup.Item action href="#link4" className="skillsTab">
                    Portfolio
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="#link1">
                    This is one of the most enjoyable projects I've worked on.
                    It started as a personal project when I recognized an
                    operational vacuum of knowledge. Given the industry the
                    attrition rate was generally high so employees with many
                    years of experience would leave, leaving a learning curve
                    towards completing certain tasks for clients without any
                    updated SOP's or even notes on how to complete those tasks.
                    Given my experience with Industrial and Operations
                    engineering and my newly found passion of coding and web
                    development I single handedly built an application that
                    would allow different roles to members of the company to
                    either to store, update and delete or just access the
                    database giving them a whole range of how to complete the
                    task to contact info for clients and employees who are able
                    to assist. The application detailed how to use the system
                    effectively and efficiently. The application utilized
                    different technologies such as JavaScript, NodeJS, React,
                    Jason Web Token (JWT), Mongo, Express and Rest API's.
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link2">
                    This was a project that I developed for a simple reason, I
                    hated writing ReadMe info for my applications on github. So
                    I built a ReadMe generator on React utilizing
                    React-Bootstrap to ask a few questions and build a read me
                    section based on the provided answers.
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link3">
                    This was a simple application that was developed to help
                    demonstrate my ability to utilize the weather API with
                    JavaScript, HTML, and CSS{" "}
                  </Tab.Pane>
                  <Tab.Pane eventKey="#link4">
                    As you may realize this is basically the section where I
                    speak about my portfolio. The portfolio that you are
                    currently using is a react based project. I hope you enjoyed
                    it so far and please feel free to contact me or read more
                    into it. Also for a more detailed description of my
                    experience please download my resume from my experience
                    timeline below 😃.{" "}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
        <Row>
          <div className="pgheader alignCenter">
            <h1>Experience</h1>
          </div>
        </Row>
        <div>
          <Card className="vCard ">
            <VerticalTimeline lineColor="linear-gradient( #0be4dd, rgb(98, 243, 1)">
              <VerticalTimelineElement
                className="vertical-timeline--two-columns"
                contentStyle={{ background: "#495057", color: "#fff" }}
                position={{ element: "right" }}
                date={<span className="text">2021 - Present</span>}
                contentArrowStyle={{ borderRight: "7px solid  #495057" }}
                layout="1-column-left"
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={WorkIcona}
                    alt="icon"
                  ></img>
                }
              >
                <h3 className="vertical-timeline-element-title">
                  Business Operations Specialist
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Toronto, ON
                </h4>
                <p>
                  Worked as an operations engineer within one of the largest
                  logistic companies in the world with the goal of increasing
                  efficiency through continuos improvement.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "#CED4DA", color: "#495057" }}
                date={<span className="text">2014 - 2016</span>}
                iconStyle={{ background: "#495057", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={WorkIconc}
                    alt="icon"
                  ></img>
                }
                contentArrowStyle={{ borderRight: "7px solid  #ADB5BD" }}
              >
                <h3 className="vertical-timeline-element-title">
                  Project Manager
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Portland, OR
                </h4>
                <p>
                  Led a team of 4 members in researching and developing the key
                  tasks that project managers implement in managing highly
                  advanced engineering projects.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={<span className="text">2015 - 2018</span>}
                contentStyle={{ background: "#495057", color: "#fff" }}
                iconStyle={{ background: "yellow", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={WorkIconb}
                    alt="icon"
                  ></img>
                }
                contentArrowStyle={{ borderRight: "7px solid  #CED4DA" }}
              >
                <h3 className="vertical-timeline-element-title">
                  Industrial Engineer
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Dubai, UAE
                </h4>
                <p>
                  Led the day to day process and production aspects of a
                  manufacturing facility for HSA (one of the largest
                  multi-national corporation of the region) including
                  forecasting, maintenance processes, business and operational
                  process improvements, and support of the sales and marketing
                  department.
                </p>
              </VerticalTimelineElement>

              {/* This is school experience section */}
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={<span className="text">2020 - 2021</span>}
                contentStyle={{ background: "#CED4DA", color: "#495057" }}
                iconStyle={{ background: "white", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={SchoolIcona}
                    alt="icon"
                  ></img>
                }
              >
                <h3 className="vertical-timeline-element-title">
                  Continued Studies in Full Stack Web Development
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Certificate Degree
                </h4>
                <p>
                  Gained experience in developing Full Stack projects utilizing
                  NodeJS, React, JavaScript, MongoDB, Express, MySQL and other
                  technologies within the Web Development atmosphere.{" "}
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={<span className="text">2015 - 2016</span>}
                contentStyle={{ background: "#495057", color: "#fff" }}
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={SchoolIconb}
                    alt="icon"
                  ></img>
                }
              >
                <h3 className="vertical-timeline-element-title">
                  Project Management
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Certificate Degree
                </h4>
                <p>
                  I accquired my project management Certificate from Portland
                  State University while attending my Graduate Studies. During
                  my tenure I managed to work as a project developer for some of
                  the largest organizations within the area.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={<span className="text">2013 - 2016</span>}
                contentStyle={{ background: "#CED4DA", color: "#495057" }}
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={SchoolIconb}
                    alt="icon"
                  ></img>
                }
              >
                <h3 className="vertical-timeline-element-title">
                  Masters of Engineering in Engineering & Technology Management
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Masters Degree
                </h4>
                <p>
                  I worked towards this graduate degree with the goal of better
                  understanding technological and engineering lifecycles. This
                  specific degree gave me a lot of insite as an engineer to how
                  best identify and employs systems and technologies to
                  streamline process and manage them more effectively.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={<span className="text">2011 - 2012</span>}
                contentStyle={{ background: "#495057", color: "#fff" }}
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={SchoolIconc}
                    alt="icon"
                  ></img>
                }
              >
                <h3 className="vertical-timeline-element-title">
                  Business Entrepreneurship
                </h3>
                <h4 className="vertical-timeline-element-subtitle">Minor</h4>
                <p>
                  Achieved a Minor in Business Entrepreneurship as a stepping
                  stone to my goal of becomming an Operational Engineer. This
                  gave me some insight to organizational management and how to
                  detect flaws and ineffeciencies to improve internatl
                  operations.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={<span className="text">2008 - 2012</span>}
                contentStyle={{ background: "#CED4DA", color: "#495057" }}
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={SchoolIconc}
                    alt="icon"
                  ></img>
                }
              >
                <h3 className="vertical-timeline-element-title">
                  Bachelor of Science Industrial Engineering
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Bachelor Degree
                </h4>
                <p>
                  Recieved a degree in Industrial Engineering with the emphasis
                  on Operations Engineering. This allowed me to develop internal
                  operational management systems to make delivery of company
                  objectives significantly more effecient.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "#495057", color: "#fff" }}
                iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
                icon={
                  <img
                    className="vertical-timeline-element-icon"
                    src={WorkIconc}
                    alt="icon"
                  ></img>
                }
                contentArrowStyle={{ borderRight: "7px solid  #CED4DA" }}
              >
                <h3 className="vertical-timeline-element-title">
                  Detailed Experience
                </h3>
                <p>
                  If you would like a more detailed Resume of my achievements
                  please feel free to <span className="text">Contact me</span>.
                  I will be more than happy to email it to you.
                </p>
                <br></br>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--no-children "
                iconStyle={{
                  background: "black",
                  color: "#fff",
                  textAlign: "center",
                }}
                icon={
                  <img
                    className="vertical-timeline-element-icon "
                    src={code}
                    alt="icon"
                  ></img>
                }
              />
            </VerticalTimeline>
          </Card>
        </div>
      </Container>
    </motion.div>
  );
}
export default Skills;
