import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserCard } from "react-ui-cards";
import headerImage from "../../images/prhd.png";
import avater from "../../images/profile2.JPG";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Project from "../../data.json";
import Button from "@mui/material/Button";
import p1 from "../../images/weather.jpg";
import p2 from "../../images/ReadMeGenerator.jpg";
import p3 from "../../images/booksearch.png";

let images = { p1, p2, p3 };
let slicedProject = Project.slice(0, 3);

function Home() {
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      animate={{ opacity: 1, x: "0" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="border colSpacing">
        <div className="colSpacing">
          <div className="pgheader alignCenter">
            <h1>Home Page</h1>
          </div>
        </div>
        <Row className="colSpacing">
          <Col sm={8} className="pg alignLeft">
            <p className="fontsize">
              Welcome and step into my world, where adventure, creativity, and
              innovation collide. As an Industrial Engineer with a thirst for
              exploration, a knack for capturing breathtaking moments through
              photography, and a love for all things tech and code, I invite you
              to join me on a journey of discovery. From my experiences as an
              Operations Engineer to the projects I've brought to life and the
              challenges I've overcome, I've compiled a small showcase of my
              work and achievements just for you. Are you ready to explore the
              boundless possibilities and see what I have in store? Then come on
              in and let's make some magic happen. The adventure starts now.
            </p>
          </Col>

          <Col className="alignCenter">
            <UserCard
              float
              header={headerImage}
              avatar={avater}
              name="Sam Thabet"
              positionName="Operations Engineer"
              stats={[
                {
                  name: "Contributions",
                  value: 300,
                },
                {
                  name: "repositories",
                  value: 44,
                },
                {
                  name: "Current Projects",
                  value: 3,
                },
              ]}
            ></UserCard>
          </Col>
        </Row>

        <Row className="colSpacing marginTop">
          <Col sm={8} className="pg alignLeft">
            <h2>Latest Posts</h2>
          </Col>
          <Col sm={4} className="btnAlignRight">
            <Link to="/blog" className="box">
              <span> View All</span>
              <i></i>
            </Link>
          </Col>
        </Row>
        <button
          className="btntrans"
          onClick={() => navigate("/blog/reactgithub")}
        >
          <div className="fontsize ">
            <p className=" alignLeft datel borderline">
              Deploy React App On Github
            </p>
            <p className="alignRight dater borderline">Jan 14</p>
          </div>
        </button>
        <button className="btntrans" onClick={() => navigate("/blog/jwt")}>
          <div className="fontsize ">
            <p className=" alignLeft datel borderline">
              Jason Web Tokens and basic use
            </p>
            <p className="alignRight dater borderline">Dec 22</p>
          </div>
        </button>
        <button
          className="btntrans"
          onClick={() => navigate("/Blog/framermotion")}
        >
          <div className="fontsize ">
            <p className=" alignLeft datel borderline">
              Framer Motion Transition For React
            </p>
            <p className="alignRight dater borderline">Dec 20</p>
          </div>
        </button>
        <button className="btntrans" onClick={() => navigate("/blog/pctomac")}>
          <div className="fontsize ">
            <p className=" alignLeft datel borderline">
              Buying a New Mac, What To Know
            </p>
            <p className="alignRight dater borderline">Dec 14</p>
          </div>
        </button>

        <Row className="colSpacing marginTop">
          <Col sm={8} className="pg alignLeft">
            <h2>Latest Projects</h2>
          </Col>
          <Col sm={4} className="btnAlignRight">
            <Link to="/projects" className="box">
              <span> View All</span>
              <i></i>
            </Link>
          </Col>
        </Row>

        <div className="row mt-lg-3 row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-4 alignCenter cardProject ">
          {/* the && sign below is used to check if we have data to map, if not to move on to the next */}
          {slicedProject &&
            slicedProject.map((project) => {
              return (
                <Card
                  sx={{
                    maxWidth: 350,
                    maxHeight: 320,
                    minHeight: 319,
                  }}
                  className="cardMui cardProject "
                  key={project.id}
                >
                  <h5 className="alignCenter text">{project.Title}</h5>
                  <CardMedia
                    component="img"
                    alt={project.Title}
                    height="100"
                    image={images[project.id]}
                    className="cardImg"
                  />{" "}
                  <CardContent>
                    <Typography variant="body2" color="white">
                      {project.Description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      id={project.id}
                      size="small"
                      className="linkButton"
                      href={project.Link}
                    >
                      Link
                    </Button>
                    <Button
                      size="small"
                      className="linkButton"
                      href={project.Github}
                    >
                      Git Hub
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
        </div>
        <p className="fontsize colSpacing pg">
          Thank you for taking the time to visit my portfolio. I hope you enjoy
          exploring my work and achievements as an Industrial Engineer and web
          developer. My passion for adventure, creativity, and innovation drives
          me to constantly seek out new challenges and opportunities to learn
          and grow. I am excited to see what the future holds and I hope you
          will join me on this journey of discovery. Thank you again for
          stopping by and I look forward to the possibility of working with you
          in the future.
        </p>
      </Container>{" "}
    </motion.div>
  );
}
export default Home;
