import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { dracula, CopyBlock } from "react-code-blocks";

//Add the code block for explanation here
const exampleCodeBlock = `const express = require('express');
const jwt = require('jsonwebtoken');

const app = express();

// Set up a secret key to sign the JWT with (You might wante to add this in the env file to keep it hiden)
const secret = 'mysupersecretkey';

// Middleware to check for a valid JWT on incoming requests
const verifyJWT = (req, res, next) => {
  // Get the JWT from the request header
  const token = req.headers['x-access-token'];

  // If there is no token, return an error
  if (!token) return res.status(401).send({ message: 'No token provided' });

  // Otherwise, verify the JWT using the secret key
  jwt.verify(token, secret, (err, decoded) => {
    // If there is an error, return an error
    if (err) return res.status(500).send({ message: 'Failed to authenticate token' });

    // Otherwise, save the decoded token to the request object
    // and call the next middleware function
    req.decoded = decoded;
    next();
  });
};

// Set up a route that requires authentication
app.get('/user', verifyJWT, (req, res) => {
  // This route is only accessible if the JWT verification
  // middleware above doesn't return an error
  res.send({ message: 'This route is protected by JWT' });
});

// Set up a route to generate a JWT
app.post('/login', (req, res) => {
  // Get the username and password from the request body
  const { username, password } = req.body;

  // Check the username and password against the database
  // (in a real application, this would be done by querying the database)
  if (username === 'user' && password === 'password') {
    // If the credentials are correct, create a JWT
    const token = jwt.sign({ username }, secret, {
      // Set the JWT to expire in one hour
      expiresIn: 3600
    });

    // Return the JWT to the client
    res.send({ token });
  } else {
    // If the credentials are incorrect, return an error
    res.status(401).send({ message: 'Incorrect username or password' });
  }
});

// Start the server
app.listen(3000);
`;

function jasonWebToken() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="border colspacing">
        <div className="colSpacing pg ">
          <div className="pgheader alignCenter ">
            <h1>Jason Web Tokens</h1>
          </div>
        </div>
        <Row className="colSpacing">
          <Col lg="9" className="pg3">
            <p>
              JSON Web Token (JWT) is a way to represent claims securely between
              two parties. It is often used in web development for creating
              authenticated sessions and handling user authentication. A JWT
              typically consists of a header, a payload, and a signature. The
              header typically consists of two parts: the type of the token,
              which is JWT, and the signing algorithm being used, such as HMAC
              SHA256 or RSA. The payload contains the claims. Claims are
              statements about an entity (typically, the user) and additional
              metadata. There are three types of claims: registered, public, and
              private claims. Registered claims are a set of predefined claims
              which are not mandatory but recommended, to provide a set of
              useful, interoperable claims. Some of the registered claims are:
              iss (issuer), exp (expiration time), sub (subject), aud
              (audience), and others. Public claims are custom claims created to
              share information between parties that agree on using them.
              Private claims are custom claims used to share information between
              parties that have a pre-existing agreement. The signature is used
              to verify that the sender of the JWT is who it says it is and to
              ensure that the message hasn't been changed in transit. The
              signature is created using the header and the payload, a secret
              key, and the signing algorithm specified in the header. JWT is
              used in web development as a means of securely transmitting
              information between parties, such as between a client and a
              server. It can be used to authenticate users, pass information
              securely between services, and protect against tampering with the
              information.
            </p>
            <br></br>
            <p>
              Please find bellow an example run of how to use the code. You are
              welcome to copy and use the code as you wish but this is for
              demonstration purposes, so it might require some modifications to
              work with your project
            </p>

            <CopyBlock
              text={exampleCodeBlock}
              language="jsx"
              showLineNumbers="true"
              wrapLines
              theme={dracula}
              className="cardImg"
            />
            <br></br>
            <p>
              You are able to use this code as a simple starting template, which
              you can modify to serve your website. Do know that there are
              vulnerabilities included with using such code. For example you
              will want to put it on the server side not the front end also you
              would want to use elements such as bcrypt to store passwords or
              even store the secret at a different location that is drawn from
              so that it is not put in your code. There are many safety
              protocols that you should read more of before using such code.
              This is just a basic starting code used for education purposes.{" "}
            </p>
          </Col>
          <Col xs={3} className="colSpacing">
            <h3 className="underline ">Tags:</h3>
            <div>
              <Badge className="tags">Javascript</Badge>
              <Badge className="tags">JWT</Badge>
              <Badge className="tags">Jason Web Token</Badge>
              <Badge className="tags">Express</Badge>
              <Badge className="tags">Back-End</Badge>
              <Badge className="tags">Web Development</Badge>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
export default jasonWebToken;
