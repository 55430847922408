import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { motion } from "framer-motion";
import proPic from "../../images/profile.JPG";

function About() {
  return (
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      animate={{ opacity: 1, x: "0" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="border colSpacing">
        <div className="colSpacing">
          <div className="pgheader alignCenter">
            <h1>About Page</h1>
          </div>
          <Row className="colSpacing">
            <Col className="pg alignCenter">
              <p>
                Welcome to my world, the world of a global adventurer and tech
                enthusiast. I have a wide educational background starting my
                journey with a bachelors in{" "}
                <span className="text">Industrial Engineering</span>. I worked
                around the world as well as attained my masters in{" "}
                <span className="text">
                  Engineering and Technology Management
                </span>
                , with a passion for <span className="text">photography</span>{" "}
                and all things tech. From Paris to Portland, Cairo to Santo
                Domingo, I have traveled the world, capturing stunning moments
                and immersing myself in diverse cultures. But my journey doesn't
                end there - with a recent foray into{" "}
                <span className="text">coding</span> and a love for bringing
                projects to life, I realized the possibilities of the tools that
                I have are endless. Recently transplanted to Toronto after
                calling Montreal home for a few years, I am always seeking new
                experiences and challenges to tackle. Thank you for visiting and
                you are always welcome to reach out!
              </p>
            </Col>
            <Col className="pg alignCenter">
              <img src={proPic} className="proPicimg" alt="Profile Pic"></img>
            </Col>
          </Row>
          <Row className="colSpacing pg">
            <Col className="alignCenter">
              <div>
                <h4 className="pg colSpacing underline alignLeft ">
                  Current PC i'm Running
                </h4>
                <ul>
                  <li>Motherboard: GIGABYTE B450 AORUS</li>
                  <li>Processor: AMD Ryzen 7 3700X </li>
                  <li>Ram: XPG 3200MHz 64GB</li>
                  <li>VideoCard: Radeon RX 570 GAMING 8GB</li>
                  <li>Keyboard: Nuphy Air75</li>
                  <li>Mouse: Razer Basilisk Pro</li>
                  <li>Screen: Samsung M8</li>
                </ul>
                <p>
                  This is my do all pc, I use it to code, game and some slight
                  photo editing. I recently also got a Macbook Air M2 that I am
                  slowly starting to transition to.
                </p>
              </div>
            </Col>
            <Col className="alignCenter">
              <div>
                <h4 className="pg colSpacing underline alignLeft ">
                  Photography Gear
                </h4>
                <ul>
                  <li>Camera: Sony A7RIII</li>
                  <li>Prefered Lens: Sony 85mm 1.8f</li>
                  <li>Secondary Lens: Sony 50mm 1.8f</li>
                  <li>Memory: Sandisk 64GB</li>
                  <li>Editing Software: Adobe Lightroom & Photoshop</li>
                </ul>
                <p>
                  I started my photography hobby a long time ago, although I did
                  not spend as much time as I would like on it but I have
                  managed to capture a lot of my trips through it. Feel free to
                  check out my{" "}
                  <a href="https://www.instagram.com/samsamosu/">
                    <span className="text">Instagram</span>{" "}
                  </a>
                  account out and dont hesitate to leave a like, comment or even
                  reach out.
                </p>
              </div>
            </Col>
            <Col className="alignCenter ">
              <div>
                <h4 className="pg colSpacing underline alignLeft ">
                  Books I Recommend & Currently Reading
                </h4>
                <ul>
                  <li>The Tipping Point: Malcom Gladwell</li>
                  <li>Lean Startup: Eric Ries</li>
                  <li>The 48 Laws of Power: Robert Greene</li>
                  <li>Alignment: Robert Kaplan & David Norton</li>
                  <li>Disrupted: Dan Lyons</li>
                </ul>
                <p>
                  There are plenty more books that I would recommend as well, so
                  if you are in the market for a new book and would like some
                  recommendations please feel free to reach out.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </motion.div>
  );
}
export default About;
