import React from "react";
import { FaLinkedin, FaInstagram, FaGithub } from "react-icons/fa";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <Container className="colSpacing ">
      <footer className="footer alignCenter">
        <a
          href="https://www.linkedin.com/in/sallam-thabet-93418518a/"
          target="blank"
          rel="Link to LinkedIn Page"
        >
          <FaLinkedin size={45} className="footericons" />
        </a>
        <a
          href="https://github.com/thabets"
          target="blank"
          rel="Link To Github Page"
        >
          <FaGithub size={45} className="footericons" />
        </a>
        <a
          href="https://www.instagram.com/samsamosu/"
          target="blank"
          rel="Link to Instagram Page"
        >
          <FaInstagram size={50} className="footericons" />
        </a>
        <br></br>
        <p className="alignCenter">Copyright © Sallam Thabet</p>
      </footer>
    </Container>
  );
}
export default Footer;
