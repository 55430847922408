import Navigation from "./components/Nav/index";
import Footer from "./components/Footer/index";
import logo from "./images/logo-removebg-preview.png";
import "@fontsource/league-spartan";
import React, { useState } from "react";
import "./App.css";
import "./button.css";
import AnimatedRoutes from "./components/AnimatedRoutes";
import "react-vertical-timeline-component/style.min.css";
import "../src/stars.css";

function App() {
  const [home, setHome] = useState("Home");

  return (
    <div>
      <header className="header">
        <a href="/" className="header">
          <img className="logo App-logo" src={logo} alt="logo"></img>
          <span> Sallam Thabet</span>
        </a>
      </header>
      <Navigation className="nav" home={home} setHome={setHome}></Navigation>
      <main>
        <AnimatedRoutes />
      </main>
      <Footer home={home} setHome={setHome}></Footer>
    </div>
  );
}

export default App;
