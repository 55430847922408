import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col,Badge } from "react-bootstrap";
import { dracula, CopyBlock } from "react-code-blocks";
import FramermotionData from "../Blog/blog.json";

//Add the code block for explanation from blog.json
let codeblock1 = FramermotionData.framermotion.codeblock1;
let codeblock2 = FramermotionData.framermotion.codeblock2;
let codeblock3 = FramermotionData.framermotion.codeblock3;
let blogpost1 = FramermotionData.framermotion.blogpost1;
let blogpost2 = FramermotionData.framermotion.blogpost2;
let blogpost3 = FramermotionData.framermotion.blogpost3;
let blogpost4 = FramermotionData.framermotion.blogpost4;

function framerMotionBl() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="border colspacing">
        <div className="colSpacing pg ">
          <div className="pgheader alignCenter ">
            <h1>Framer Motion</h1>
          </div>
        </div>
        <Row className="colSpacing">
          <Col lg="9" className="pg3">
            <p>{blogpost1} </p>

            <CopyBlock
              text={codeblock1}
              language="jsx"
              wrapLines
              theme={dracula}
              className="cardImg"
            />
            <br></br>
            <p>{blogpost2}</p>

            <CopyBlock
              text={codeblock2}
              language="jsx"
              wrapLines
              theme={dracula}
              className="cardImg"
            />
            <br></br>
            <p>{blogpost3}</p>

            <CopyBlock
              text={codeblock3}
              language="jsx"
              wrapLines
              theme={dracula}
              className="cardImg"
            />
            <br></br>
            <p>{blogpost4}</p>
          </Col>
          <Col xs={3} className="colSpacing">
            <h3 className="underline ">Tags:</h3>
            <div>
              <Badge className="tags">Javascript</Badge>
              <Badge className="tags">FramerMotion</Badge>
              <Badge className="tags">FramermotionData</Badge>
              <Badge className="tags">Motion-Div</Badge>
              <Badge className="tags">Motion</Badge>
              <Badge className="tags">Code</Badge>
              <Badge className="tags">React</Badge>
              <Badge className="tags">Front-End</Badge>
              <Badge className="tags">Web Development</Badge>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
export default framerMotionBl;
