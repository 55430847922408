import React from "react";
import { Container } from "react-bootstrap";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import p2 from "../../images/ReadMeGenerator.jpg";
import p1 from "../../images/weather.jpg";
import p3 from "../../images/booksearch.png";
import p4 from "../../images/workdayplanner.jpg";
import p5 from "../../images/Notes.jpeg";
import p6 from "../../images/foodielove.png";
import p7 from "../../images/passwordgenerator.png";
import p8 from "../../images/cryptoapi.jpg";
import p9 from "../../images/chatintro.jpg";
import { motion } from "framer-motion";
import Project from "../../data.json";

let images = {
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
};
function Projects() {
  return (
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      animate={{ opacity: 1, x: "0" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="border colSpacing">
        <div className="pgheader alignCenter colSpacing">
          <h1>Projects Page</h1>
        </div>
        <div className="row mt-lg-3 row-cols-xs-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 g-4 alignCenter cardProject ">
          {/* the && sign below is used to check if we have data to map, if not to move on to the next */}
          {Project &&
            Project.map((project) => {
              return (
                <Card
                  sx={{
                    maxWidth: 350,
                    maxHeight: 320,
                    minHeight: 319,
                  }}
                  className="cardMui cardProject "
                  key={project.id}
                >
                  <h5 className="alignCenter text">{project.Title}</h5>
                  <CardMedia
                    component="img"
                    alt={project.Title}
                    height="100"
                    image={images[project.id]}
                    className="cardImg"
                  />{" "}
                  <CardContent>
                    <Typography variant="body2" color="white">
                      {project.Description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      id={project.id}
                      size="small"
                      className="linkButton"
                      href={project.Link}
                    >
                      Link
                    </Button>
                    <Button
                      size="small"
                      className="linkButton"
                      href={project.Github}
                    >
                      Git Hub
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
        </div>
      </Container>
    </motion.div>
  );
}
export default Projects;
