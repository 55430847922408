import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "./About/index";
import Blog from "./Blog/index";
import Projects from "./Projects/index";
import Skills from "./Skills/index";
import Home from "./Home/index";
import Contact from "./Contact/index";
import JasonWebToken from "./Blog/jwt";
import Framermotion from "./Blog/framermotion";
import PcToMac from "./Blog/pctomac";
import ReactOnGithub from "./Blog/reacttogithub";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog/jwt" element={<JasonWebToken />} />
        <Route path="/blog/framermotion" element={<Framermotion />} />
        <Route path="/blog/pctomac" element={<PcToMac />} />
        <Route path="/blog/reactgithub" element={<ReactOnGithub/>}/>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
