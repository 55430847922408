import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Badge } from "react-bootstrap";
import PcToMacData from "./blog.json";

//Adding the blogpost blocks from blog.json
let blogpost1 = PcToMacData.PcToMac.blogpost1;
let blogpost2 = PcToMacData.PcToMac.blogpost2;
let blogpost3 = PcToMacData.PcToMac.blogpost3;
let blogpost4 = PcToMacData.PcToMac.blogpost4;
let conclusion = PcToMacData.PcToMac.conclusion;

function windowsToMac() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid="md" className="border colspacing">
        <div className="colSpacing pg ">
          <div className="pgheader alignCenter ">
            <h1>Buying a New Mac, What To Know</h1>
          </div>
        </div>
        <Row className="colSpacing">
          <Col xs={8} className="pg3">
            <p>{blogpost1}</p>
            <br></br>
            <p>{blogpost2}</p>
            <br></br>
            <p>{blogpost3}</p>
            <br></br>
            <p>{blogpost4}</p>
            <br></br>
            <p>{conclusion}</p>
            <br></br>
          </Col>
          <Col xs={4} className="colSpacing">
            <h3 className="underline">My Apple Developer Machine:</h3>
            <ul>
              <li>Device: MacBook Air</li>
              <li>Processor: M2</li>
              <li>Ram Memory: 16GB</li>
              <li>Storage Memory: 512GB</li>
              <li>Color: Midnight</li>
            </ul>
            <h3 className="underline ">Tags:</h3>
            <Badge className="tags">Apple</Badge>
            <Badge className="tags">MacBook Air</Badge>
            <Badge className="tags">M2</Badge>
            <Badge className="tags">Midnight</Badge>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
export default windowsToMac;
