import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useForm, ValidationError } from "@formspree/react";
import { motion } from "framer-motion";

function Contact() {
  const [state, handleSubmit] = useForm("xknawqoe");
  if (state.succeeded) {
    return (
      <p className="alignCenter border">
        Thanks for contacting me I will get back to you shortly
      </p>
    );
  }
  return (
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      animate={{ opacity: 1, x: "0" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="border ">
        <div className="colSpacing ">
          <div className="pgheader alignCenter ">
            <h1>Contact from</h1>
          </div>

          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs="6" className=" alignCenter  contactLabel">
                <div className="contactLabel alignCenter">
                  <label for="full-name">First & Last Name</label>
                  <input
                    type="text"
                    name="name"
                    id="full-name"
                    className="contactText"
                    required="true"
                  ></input>
                </div>
              </Col>

              <Col xs="6" className=" alignCenter contactLabel">
                <div className="contactLabel alignCenter">
                  <label htmlFor="email">Email Address</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="contactText"
                    required="true"
                  />

                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className=" pg alignCenter contactLabel">
                <label for="Message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className="contactText"
                  rows="4"
                ></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </Col>
            </Row>
            <Row className="colSpacing ">
              <div className="btnContact">
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="box btnContacttwo"
                >
                  <span>Submit</span>
                  <i></i>
                </button>
              </div>
            </Row>
          </form>
        </div>
      </Container>
    </motion.div>
  );
}

export default Contact;
