import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import codeIcon from "../../images/codeicon.png";

function Blog() {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, x: "-100%" }}
      animate={{ opacity: 1, x: "0" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.75 }}
    >
      <Container fluid className="border colSpacing">
        <div className="colSpacing pg ">
          <div className="pgheader alignCenter ">
            <h1>Blog Page</h1>
          </div>
        </div>
        <Row>
          <Col xs={8} className="pg3 colSpacing">
            <h2 className="h2mar">2023</h2>
            <button
              className="btntrans"
              onClick={() => navigate("/blog/reactgithub")}
            >
              <div className="fontsize ">
                <p className=" alignLeft datel borderline">
                  Deploy React App On Github
                </p>
                <p className="alignRight dater borderline">Jan 14</p>
              </div>
            </button>
            <h2 className="h2mar marginTop">2022</h2>
            <button className="btntrans" onClick={() => navigate("/blog/jwt")}>
              <div className="fontsize ">
                <p className=" alignLeft datel borderline">
                  Jason Web Tokens and basic use
                </p>
                <p className="alignRight dater borderline">Dec 22</p>
              </div>
            </button>
            <button
              className="btntrans"
              onClick={() => navigate("/blog/framermotion")}
            >
              <div className="fontsize ">
                <p className=" alignLeft datel borderline">
                  Framer Motion Transition For React
                </p>
                <p className="alignRight dater borderline">Dec 20</p>
              </div>
            </button>
            <button
              className="btntrans"
              onClick={() => navigate("/blog/pctomac")}
            >
              <div className="fontsize ">
                <p className=" alignLeft datel borderline">
                  Buying a New Mac, What You Need
                </p>
                <p className="alignRight dater borderline">Dec 14</p>
              </div>
            </button>
            {/* <button className="btntrans " onClick={() => navigate("/sopMERN")}>
              <div className="fontsize ">
                <p className=" alignLeft datel borderline">
                  Best steps to set up a MERN stack project
                </p>
                <p className="alignRight dater borderline ">Dec 07</p>
              </div>
            </button>
            <button className="btntrans " onClick={() => navigate("/sopMERN")}>
              <div className="fontsize ">
                <p className=" alignLeft datel borderline">
                  How to run a React App on Github Pages
                </p>
                <p className="alignRight dater borderline ">Dec 01</p>
              </div>
            </button> */}
          </Col>
          <Col xs={4}>
            <img src={codeIcon} className="proPicimg" alt="Code Icon"></img>
            <p>
              {" "}
              I started this blog page for one simple reason, and it is to help
              simplify for new developers different issues that I personally
              faced when I started my career in Web Development.
            </p>{" "}
            <br></br>
            <p>
              If you like what I put together and have any recommendations to
              what you would like for me to tackle feel free to reach out to me
              and I will do my best to post something shortly after.
            </p>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
export default Blog;
